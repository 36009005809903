import * as React from 'react'
import { css } from '@emotion/core'
import styled from '@emotion/styled'
import { StyledHeading } from '../Heading'
import { dimensions, widths } from '../../styles/variables'
import { StyledParagraph } from '../Base'

const align = props =>
  css`
    text-align: ${props.align || 'center'};
  `

const Statement = styled(({ ...props }) => <div {...props} />)`
  width: ${props => props.width || '100%'};
  max-width: ${widths.md}px;
  margin: ${props => props.margin || '4.8rem auto 4.8rem auto'};
  padding: ${props => props.padding || '0'};
  ${align}

  ${StyledHeading} {
    margin: ${props => props.margin || '0 0 1.2rem 0'};
    font-size: ${dimensions.headingSizes.h2}rem;
    //font-weight: ${dimensions.fontWeight.normal};
  }

  ${StyledParagraph} {
    font-size: ${dimensions.fontSize.xl}px;
    font-weight: ${dimensions.fontWeight.light};
  }

  .cta {
    margin-top: 2.4rem;
  }
`
export default Statement
