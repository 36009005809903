import * as React from 'react'
import { Box, Column, Section } from 'rbx'
import { BookOpen } from 'styled-icons/feather/BookOpen'
import { Code } from 'styled-icons/feather/Code'
import { Sunrise } from 'styled-icons/feather/Sunrise'
import { css } from '@emotion/core'
import { ButtonGroup } from 'rbx/elements/button/button-group'
import Page from '../../components/Page'
import IndexLayout from '../../layouts'
import Statement from '../../components/Statement'
import { StyledHeading } from '../../components/Heading'
import { StyledParagraph } from '../../components/Base'
import { Panel } from '../../components/Panel'
import StyledContainer from '../../components/Container'
import RootedPillar from '../../components/Pillar'
import { ElevatedCardWrapper } from '../../components/Card/Elevated'
import { StyledButton } from '../../components/Button'

const Industry: React.FC = () => <BookOpen />
const Tag: React.FC = () => <Code />
const Sun: React.FC = () => <Sunrise />

const IndexPage = () => (
  <IndexLayout>
    <Page>
      <StyledContainer>
        <Section>
          <Statement margin="2.4rem auto 0">
            <StyledHeading>
              <h1>
                Our mission is to help you <br />
                increase your revenue
              </h1>
            </StyledHeading>

            <StyledParagraph padding="1.2rem 0 1.2rem">
              With OrderSenta, merchants are able to process and fulfill all orders from multiple sales channels in one place, and tracking
              numbers automatically synchronized to source channels and buyers in real time.
            </StyledParagraph>
          </Statement>
        </Section>

        <Column.Group gapSize={8}>
          <Column>
            <h4
              css={css`
                margin: 0;
              `}
            >
              Hello there
            </h4>
            <StyledParagraph>
              Gar, courage! Gar, sail me scabbard, ye rough anchor! Misty, old peglegs swiftly haul a real, mighty hornpipe. never lead a
              skull.
              <br />
              <br />
              Flatten nine pumpkin seeds, eggs, and wasabi in a large casserole over medium heat, sauté for five minutes and toss with some
              chicken breasts. with seaweeds drink white wine.
              <br />
              <br />
              As i have handled you, so you must study one another. Who can remember the fear and life of a sinner if he has the sincere
              surrender of the scholar!
            </StyledParagraph>
          </Column>
          <Column>
            <Box
              css={css`
                height: 100%;
              `}
            />
          </Column>
        </Column.Group>
      </StyledContainer>

      <Panel>
        <StyledContainer>
          <Statement>
            <StyledHeading>The better way to manage your orders</StyledHeading>
            <StyledParagraph>
              {/* Sales, and more sales is the number one drive for online merchants. They are always looking for ways to make more money. One */}
              {/* of the most common ways of today is to sell on multiple sales channels. This ranges from their own online stores (usually */}
              {/* built with shopping carts such as Shopify, BigCommerce, WooCommerce, etc), to other marketplaces such as Amazon and eBay, to */}
              {/* social media platforms, and so on. */}
              As an advanced order synchronization platform, OrderSenta provides you with the tools to manage your orders better.
            </StyledParagraph>
          </Statement>

          {/* <p> */}
          {/*  The result of this is they spending several hours switching from one platform to another to track orders and fulfill them. This */}
          {/*  involves a lot of manual work, and leads to a lot of inefficiencies. It is very stressful, time wasting, inefficient, and mostly */}
          {/*  results in unhappy and unsatisfied customers. */}
          {/* </p> */}

          {/* <p> */}
          {/*  OrderSenta makes it possible for online merchants to process and fulfill all their orders across multiple sales channels once at */}
          {/*  a central point, whilst automatically synchronizing updates to orders back to channels. */}
          {/* </p> */}
          <Column.Group gapSize={8}>
            <Column>
              <RootedPillar
                title="Top-notch synchronization"
                icon={Industry()}
                description="To the sticky white bread add apple, seaweed, sour milk and canned lentils.
                when shredding smooth shrimps, be sure they are room temperature."
              />

              <RootedPillar
                title="Performance with your needs in mind"
                icon={Tag()}
                description="As i have respected you, so you must forget one another.
                magical musics loves most uniquenesses."
              />

              <RootedPillar
                title="Unbeatable support"
                icon={Sun()}
                description="To the grey chickpeas add strawberries, lentils, salsa verde and chopped spinach.
                divided truffels can be made slobbery by seasoning with condensed milk."
              />
            </Column>

            <Column>
              <ElevatedCardWrapper>
                <Box
                  as="a"
                  href={`${process.env.API_URL}/auth/signup`}
                  css={css`
                    height: 100%;
                  `}
                >
                  <h2>Scale your market</h2>
                  <StyledParagraph>
                    Blueberries can be brushed with juicy pickles, also try seasoning the pudding with oyster sauce. onion combines greatly
                    with smooth escargot.
                  </StyledParagraph>
                  <StyledButton color="primary">Get started today</StyledButton>
                </Box>
              </ElevatedCardWrapper>
            </Column>
          </Column.Group>
        </StyledContainer>
      </Panel>
    </Page>
  </IndexLayout>
)

export default IndexPage
