import React from 'react'
import styled from '@emotion/styled'
import { colors, dimensions } from '../../styles/variables'

const Item = styled.li``

const Wrapper = styled.ul`
  display: flex;
  align-items: flex-start;
  margin-bottom: 3.6rem;
  font-size: ${dimensions.fontSize.large}px;

  ${Item}:nth-of-type(2) {
    padding-left: 1.2rem;
  }
`

const Title = styled.div`
  margin-bottom: 0.6rem;
  font-size: ${dimensions.headingSizes.h4}rem;
  line-height: 1;
`

const Icon = styled.div`
  width: 2.4rem;
  color: ${colors.brand.main};
`

interface PillarProps {
  title: string
  icon: string
  description: string
}

const RootedPillar: React.FC<PillarProps> = ({ title, icon, description }) => {
  return (
    <Wrapper>
      <Item>
        <Icon>{icon}</Icon>
      </Item>
      <Item>
        <Title>{title}</Title>
        <div>{description}</div>
      </Item>
    </Wrapper>
  )
}

export default RootedPillar
