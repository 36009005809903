import styled from '@emotion/styled'
import { colors } from '../../styles/variables'

export const ElevatedCardWrapper = styled.div`
  .box {
    box-shadow: none;
    //box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.15);
    border: 1px solid ${colors.ui.light};
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.4rem;
    cursor: pointer;
    text-align: center;
    transition: transform 0.3s cubic-bezier(0.25, 0.1, 0.25, 1), box-shadow 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);

    :hover {
      box-shadow: 0 25px 60px 0 rgba(0, 0, 0, 0.1);
      transform: translateY(-2px);
      text-decoration: none;
    }
  }

  :hover {
    a {
      text-decoration: none;
    }
  }

  svg {
    color: ${colors.brand.main};
  }
`
