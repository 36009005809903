import * as React from 'react'
import styled from '@emotion/styled'
import { StyledHeading } from '../Heading'
import Statement from '../Statement'
import { colors } from '../../styles/variables'

export const Panel = styled(({ ...props }) => <div {...props} />)`
  margin: ${props => props.margin || '4.8rem 0 0'};
  padding: ${props => props.padding || '4.8rem 0 4.8rem'};

  ${StyledHeading} {
    margin-top: 0;
  }

  ${Statement} {
    margin-top: 0;
    //margin-bottom: 0;
  }
`

export const ColoredPanel = styled(({ ...props }) => <Panel {...props} />)`
  position: relative;
  height: ${props => props.height};
  background: ${props => props.background || colors.white};
`
